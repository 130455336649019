<template>
  <div class="serviceManager">
    <div class="" v-if="shopAuth === false">
      <div style="height: 1px;" class="sctp-bg-white"></div>
      <el-alert
        type="info"
        :closable="false"
        description="您的店铺尚未审核通过，审核通过后即可上架服务"
        show-icon>
      </el-alert>
    </div>
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom" size="small" :inline="true" lable-width="80px"
               ref="serviceSearchForm" :model="searchForm">
        <el-form-item prop="serviceName" label="服务名称:">
          <el-input v-model="searchForm.serviceName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item prop="priceMin" label="价格:">
          <el-col :span="11">
            <el-input v-model="searchForm.priceMin" placeholder=""></el-input>
          </el-col>
          <el-col class="sctp-tc" :span="2">~</el-col>
          <el-col :span="11">
            <el-input v-model="searchForm.priceMax" placeholder=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item prop="stockMin" label="库存量:">
          <el-col :span="11">
            <el-input v-model="searchForm.stockMin" placeholder="" @blur="blur1"></el-input>
          </el-col>
          <el-col class="sctp-tc" :span="2">~</el-col>
          <el-col :span="11">
            <el-input v-model="searchForm.stockMax" placeholder="" @blur="blur2"></el-input>
          </el-col>
        </el-form-item>
        <el-button @click="resetForm('serviceSearchForm')" size="small">重置</el-button>
        <el-button size="small" type="primary" @click="queryServices">搜索</el-button>
        <el-button @click="goPage('/shop/release/serivce')" type="primary" size="small"
                   icon="el-icon-plus">发布服务
        </el-button>
      </el-form>
      <el-table
        @selection-change="selectionChange"
        :data="services"
      >
        <el-table-column
          prop="goodsname"
          label="服务信息"
          width="280">
          <template slot-scope="item">
            <div>#{{ item.row.serviceId }}</div>
            <div class="sctp-flex">
              <div class="sctp-mar-r10">
                <el-image
                  style="width: 65px; height: 65px"
                  :src="item.row.cover[0]"
                  fit="contain">
                  <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                       style="height: 100%;">
                    暂无图片
                  </div>
                </el-image>
              </div>
              <div class="sctp-flex-item-1">
                <div class="sctp-bold sctp-ellipsis--l2">{{ item.row.serviceName }}</div>
                <div class="sctp-font-12 sctp-ellipsis--l2">{{ item.row.hits || 0 }}浏览</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="服务价格">
          <template slot-scope="scope">
            <div class="sctp-flex sctp-flex-center sctp-flex-column">
              <div style="font-weight: bold;" class="sctp-font-16">{{
                  scope.row.price==null? "" :scope.row.price.toMoney()
                }}/{{ scope.row.unit }}
              </div>
              <i
                @click="optionsConfig.onClick('editPrice', scope.row, scope.$index)"
                class="el-icon-edit"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          prop="amount"
          label="数量/已售">
          <template slot-scope="scope">
            <div>{{ scope.row.amount }}
              <i
                @click="optionsConfig.onClick('editStock', scope.row, scope.$index)"
                class="el-icon-edit"></i>/ {{ scope.row.salesVolume || 0}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="90"
          label="是否上架">
          <template slot-scope="scope">
            <template v-if="scope.row.auditFlag === 3">
              <el-tag size="small" type="warning" effect="dark">
                强制下架
              </el-tag>
            </template>
            <template v-else>
              <el-tooltip
                class="item" effect="dark"
                v-if="scope.row.status === 1"
                :content="`上架截至日期${dateToString(scope.row.shelvesDeadline)}`"
                placement="top">
                <el-tag size="small" effect="dark">
                  已上架
                </el-tag>
              </el-tooltip>
              <el-tag size="small" type="info" effect="dark" v-else-if="scope.row.status === 0">
                未上架
              </el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          label="审核结果">
          <template slot-scope="scope">
            <el-tag size="small" type="danger" effect="dark" v-if="scope.row.auditFlag === 0">
              未审核
            </el-tag>
            <el-tag size="small" effect="dark" v-else-if="scope.row.auditFlag === 1">
              已通过
            </el-tag>
            <template v-else-if="scope.row.auditFlag === 2 || scope.row.auditFlag === 3">
              <el-tooltip effect="dark" :content="scope.row.auditMemo" placement="top">
                <el-tag size="small" type="danger" effect="dark">
                  {{ scope.row.auditFlag === 2 ? '未通过' : '强制下架' }}
                </el-tag>
              </el-tooltip>
            </template>
            <el-tooltip class="item" effect="dark" content="点击查看审核历史" placement="top">
              <a
                @click="viewHistoryConfig.viewHistory(scope.row.serviceId)"
              ><i class="el-icon-question"></i></a>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100px"
          label="最近更新">
          <template slot-scope="scope">
            {{ dateToString(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作" width="120">
          <template slot-scope="scope">
            <template v-if="scope.row.temporaryStorage">
              <div class="sctp-mar-b5">
                <el-button
                  @click="goPage(`/shop/release/serivce?serviceId=${scope.row.serviceId}`)"
                  size="mini"
                >
                  继续完善
                </el-button>
              </div>
              <div class="sctp-mar-b5">
                <el-button
                  :disabled="scope.row.salesVolume > 0"
                  @click="optionsConfig.onClick('del', scope.row, scope.$index)"
                  size="mini" type="danger"
                >
                  删除
                </el-button>
              </div>
            </template>
            <template v-else>
            <template v-if="scope.row.auditFlag === 1">
              <div class="sctp-mar-b5" v-if="scope.row.status === 0">
                <el-button
                  size="mini"
                  @click="optionsConfig.onClick('up', scope.row, scope.$index)"
                >
                  上架
                </el-button>
              </div>
              <div class="sctp-mar-b5" v-else>
                <el-button
                  size="mini"
                  @click="optionsConfig.onClick('down', scope.row, scope.$index)"
                >
                  下架
                </el-button>
              </div>
            </template>
            <div class="sctp-mar-b5">
              <el-button
                @click="optionsConfig.onClick('edit', scope.row, scope.$index)"
                size="mini"
              >
                编辑
              </el-button>
            </div>
            <div class="sctp-mar-b5">
              <el-button
                size="mini" type="primary"
                @click="optionsConfig.onClick('preview', scope.row, scope.$index)"
              >
                预览
              </el-button>
            </div>
            <div class="sctp-mar-b5">
              <el-button
                size="mini" type="danger"
                :disabled="scope.row.salesVolume > 0"
                :title="scope.row.salesVolume ? '已售服务无法删除' : ''"
                @click="optionsConfig.onClick('del', scope.row, scope.$index)"
              >
                删除
              </el-button>
            </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="pageConfig.page"
          :page-size="pageConfig.pageSize"
          @size-change="pageConfig.handleSizeChange"
          @current-change="pageConfig.handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="pageConfig.total">
        </el-pagination>
      </div>

      <el-dialog title="审核历史" class="auditHistory" :visible.sync="viewHistoryConfig.showFlag">
        <audit-history type="SERVICE" :id="activeServiceId"></audit-history>
      </el-dialog>

    </el-card>
    <el-dialog width="600px" center title="本服务上架需支付服务费" :visible.sync="payDialogConfig.showFlag">
      <pay-dialog type="SERVICE" @pay="payDialogConfig.onPay"></pay-dialog>
    </el-dialog>
  </div>
</template>

<script>
import {goods, service} from "../../../apis";
import validator from "@/resources/js/validator";

const config = require('../../../resources/js/config');
export default {
  name: 'serviceIndex',
  components: {
    payDialog: () => import('@PAGE/users-center-manage/requirement-manage/RequirementOnlinePay'),
    AuditHistory: () => import('@PAGE/users-center-manage/AuditHistory.vue'),
  },
  data() {
    return {
      services: [],
      pageConfig: {
        page: 1,
        pageSize: 10,
        total: 0,
        handleSizeChange: (pageSize) => {
          this.pageConfig.pageSize = pageSize;
          this.queryServices()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.queryServices()
        }
      },
      selectedItems: [],
      searchForm: {
        serviceName: null,
        priceMin: null,
        priceMax: null,
        stockMin: null,
        stockMax: null
      },
      viewHistoryConfig: {
        showFlag: false,
        viewHistory: (serviceId) => {
          this.activeServiceId = serviceId;
          this.viewHistoryConfig.showFlag = true;
        },
      },
      activeServiceId: null,
      payDialogConfig: {
        showFlag: false,
        onPay: ({payWay, price, date}) => {
          return service.upperPay({
            userId: this.user.userId,
            serviceId: this.activeServiceId,
            date: date,
            payWay: payWay,
          }).then(res => {
            this.payDialogConfig.showFlag = false;
            this.$message.success('操作成功');
            this.queryServices();
          })
        },
      },
      shopAuth: null,
      optionsConfig: {
        options: {
          up: ({item, index}) => {
            if (item.state === 2) {
              this.$alert(`已被强制下架，无法上架`, '提示', {
                confirmButtonText: '确定',
              });
              return;
            }
            this.doChangeStatus(1, item.serviceId).then(res => {
              this.$message.success('上架成功')
            });
          },
          del: ({item, index}) => {
            this.$confirm(`确认删除？`, '提示', {
              type: 'warning'
            }).then(() => {
              service.del({
                serviceId: item.serviceId,
                userId: this.user.userId,
              }).then(res => {
                this.$message.success('删除成功');
                this.queryServices();
              })
            })
          },
          down: ({item, index}) => {
            this.$confirm('确定要下架?', '提示', {})
            .then(() => {
              this.doChangeStatus(0, item.serviceId).then(res => {
                this.$message.success('下架成功')
              });
            })
          },
          preview: ({item, index}) => {
            this.openPage(`/service/view/${item.serviceId}`);
          },
          editPrice: ({item, index}) => {
            this.$prompt('请输入服务价格', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputValidator: (value) => {
                if (!validator.validMoney(value, false)) {
                  return '金额数值不合法';
                }
                if (value < 1 || value > 10000000) {
                  return '价格范围只能1元~1000万元之间';
                }
              },
            }).then(({value}) => {
              goods.updatePrice({
                id: item.serviceId,
                type: 'SERVICE',
                salePrice: value,
              }).then(res => {
                item.price = value;
                this.$message.success('修改成功');
              })
            })
          },
          editStock: ({item, index}) => {
            this.$prompt('请输入库存', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /^(?!0)(?:[0-9]{1,4}|10000)$/,
              inputErrorMessage: '库存限制范围 1 - 10000'
            }).then(({value}) => {
              goods.updateStock({
                id: item.serviceId,
                stock: value,
                type: 'SERVICE',
              }).then(res => {
                item.amount = value;
                this.$message.success('修改成功');
              })
            })
          },
          edit: ({item, index}) => {
            let editPage = () => {
              this.$router.push({
                path: `/shop/release/serivce?serviceId=${item.serviceId}`,
              })
            };
            if (item.auditFlag === 1) {
              this.$confirm(`${item.status === 1 ? '编辑需要先下架，' : ''}重新编辑后需要重新审核，确定编辑吗？`, '提示', {
                type: 'warning'
              }).then(() => {
                if (item.status === 1) {
                  this.doChangeStatus(0, item.serviceId).then(() => {
                    editPage();
                  });
                } else {
                  editPage();
                }
              });
              return;
            }
            editPage();
          },
        },
        onClick: (option, item, index) => {
          this.activeServiceId = item.serviceId;
          if (this.optionsConfig.options[option]) {
            this.optionsConfig.options[option].call(null, {item: item, index: index});
          }
        }
      },
    }
  },
  methods: {
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      Object.keys(this.searchForm).forEach(key => {
        this.searchForm[key] = null;
      })
    },
    doBatchDelete(ids) {
      return this.$request.post({
        reqcode: '1094',
        reqdesc: '批量删除',
        ids: ids
      }).then(res => {
        this.$message.success('删除成功');
        this.queryServices()
      })
    },
    batchDelete() {
      if (this.selectedItems.length === 0) {
        this.$message.warning('请至少选择一条数据')
      } else {
        this.$confirm('确定要执行该操作嘛', '提示', {}).then(() => {
          this.doBatchDelete(this.selectedItems.map(item => item.serviceId))
        })
      }
    },
    selectionChange(selectedArray) {
      this.selectedItems = selectedArray
    },
    queryServices() {
      const {pageConfig: {page, pageSize}, searchForm} = this;
      service.selectUserService({
        page: page,
        limit: pageSize,
        userId: this.user.userId,
        ...searchForm
      }).then(res => {
        const {retdata = [], count = 0, shopAuth} = res;
        this.shopAuth = shopAuth;
        this.services = retdata;
        this.pageConfig.total = count
      })
    },
    doChangeStatus(status, serviceId) {
      return this.$request.post({
        reqcode: '1110',
        reqdesc: 'change status',
        status: status,
        serviceId: serviceId,
        userId: this.user.userId
      }).then(res => {
        this.queryServices();
      }).catch(res => {
        if (res && res.retCode === '9002') {
          this.payDialogConfig.showFlag = true;
        }
        return Promise.reject(res);
      })
    },
    blur1(){
      if (this.searchForm.stockMin != null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.searchForm.stockMin))
        {
          this.$alert('输入错误！库存量不能包含字母')
          this.searchForm.stockMin = 0
          return
        }
        let a = this.searchForm.stockMin * 1;
        if(a<0)
        {
          this.$alert('库存量不能为负数')
          this.searchForm.stockMin=0
          return
        }
        if (this.searchForm.stockMax != null){
          let b = this.searchForm.stockMax * 1;
          if (a > b) {
            this.$alert("最小库存量不能大于最大库存量")
            this.searchForm.stockMin = 0
          }
        }

      }
    },
    blur2(){
      if (this.searchForm.stockMax != null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.searchForm.stockMax))
        {
          this.$alert('输入错误！库存量不能包含字母')
          this.searchForm.stockMax = 0
          return
        }
        let a = this.searchForm.stockMax * 1;
        if(a<0)
        {
          this.$alert('库存量不能为负数')
          this.searchForm.stockMax=0
          return
        }
        if (this.searchForm.stockMin != null){
          let b = this.searchForm.stockMin * 1;
          if (a < b) {
            this.$alert("最大库存量不能小于最小库存量")
            this.searchForm.stockMax = 0
          }
        }

      }
    }
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.queryServices()
  }
}
</script>

<style scoped lang="scss">
.searchFrom ::v-deep .el-form-item__content {
  width: 130px !important;
}

.serviceManager {
  ::v-deep {
    .el-dialog {
      width: 80%;
    }

    .el-dialog__body {
      max-height: 70vh;
      overflow-y: auto;
    }

    td {
      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      :hover .el-icon-edit {
        display: inline-block;
      }
    }
  }

  .auditHistory ::v-deep .el-dialog__body {
    padding-top: 0;

    & .el-table {
      font-size: 12px !important;
    }
  }
}
</style>
